













import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Office } from 'client-website-ts-library/types';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class TenancyApplicationForm extends Mixins(View) {
  private formCtx: Context | null = null

  private office: Office | null = null

  mounted() {
    const officeId = '3e0c28b3-f233-458f-8f3f-24e43d9f1082'; // Bundaberg Region

    API.Offices.Get(officeId).then((office) => {
      this.office = office;
    });

    const ctx: Context = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.Office,
          Id: officeId,
        },
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: 'e347564a-1ebf-4bba-a5e9-dbe169440925', // EPB4670QLD
        },
      ],
    };

    const listingId = this.$route.query.listingId ?? undefined;

    if (listingId) {
      ctx.Items.push({
        Type: ContextItemType.Listing,
        Id: listingId as string,
      });
    }

    this.formCtx = ctx;
  }
}
